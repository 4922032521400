import { createGlobalStyle } from 'styled-components';

import colors from './colors';

import '../../../static/fonts/fonts.css';

const GlobalStyle = createGlobalStyle`
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    box-sizing: border-box;
    font-family: 'Source Sans 3', serif;

    body {
      overscroll-behavior: none;
      color: ${colors.galaxy};
      margin: 0;
      font-size: 1rem;
      font-weight: 400;
      word-wrap: break-word;


      h1 {
        line-height: 1.1;
        font-size: 2.5rem;
        font-weight: 300;
        margin: 0;
      }

      h2 {
        font-weight: 400;
        font-size: 1.8rem;
        margin: 0;
      }

      h3 {
        font-weight: 400;
        font-size: 1.2rem;
        margin: 0;
      }

      h4 {
        font-weight: 400;
        margin: 0;
      }

      p {
        margin: 0;
        font-size: 1rem;
      }
      
      strong {
        font-size: 1rem;
        font-weight: 400;
        margin: 0;
      }

      button {
        font-size: .95rem;
        font-weight: 400;
      }

      a {
        text-decoration: none;
        font-size: inherit;
        color: ${colors.leaf};
        
        &:visited, &:focus, &:active, &:hover {
          opacity: .75;
        }
      }
      
      ol {
        padding: 0;
        margin: 0 16px;
      }

      ul {
        list-style-type: "- ";
        padding: 0;
        margin: 0 16px;
      }

      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;

      }
    }
  }
`;
export default GlobalStyle;
