import React from 'react';
import { Link } from 'gatsby';

import Layout from '../templates/Layout';

const schemaMarkup = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  logo: 'www.theplantifullife.ca/logo.png',
  name: 'Plantiful',
  url: 'www.theplantifullife.ca',
};

const Home = () => (
  <Layout schemaMarkup={schemaMarkup} title="Home - Plantiful">
    <Link to="/blog/">Blog</Link>
  </Layout>
);

export default Home;
