exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-gifts-index-tsx": () => import("./../../../src/pages/blog/gifts/index.tsx" /* webpackChunkName: "component---src-pages-blog-gifts-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-propagation-index-tsx": () => import("./../../../src/pages/blog/propagation/index.tsx" /* webpackChunkName: "component---src-pages-blog-propagation-index-tsx" */),
  "component---src-pages-blog-tools-index-tsx": () => import("./../../../src/pages/blog/tools/index.tsx" /* webpackChunkName: "component---src-pages-blog-tools-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/BlogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */)
}

