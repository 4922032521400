import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../../../templates/Layout';
import BlogOverview from '../../../ui/organisms/BlogOverview';

// eslint-disable-next-line no-undef
const Blog = ({ data }: PageProps<Queries.AllBlogPostsQuery>) => {
  const posts = data?.allMarkdownRemark?.nodes;

  if (posts?.length === 0) {
    return (
      <Layout title="Blog - Tools - Plantiful">
        <section>
          <p>No blog posts found.</p>
        </section>
      </Layout>
    );
  }

  return (
    <Layout title="Blog - Tools - Plantiful">
      <BlogOverview posts={posts} />
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query AllBlogPosts {
    allMarkdownRemark(filter: { frontmatter: { category: { eq: "tools" } } }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          imageName
          description
        }
      }
    }
  }
`;
