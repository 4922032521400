import React from 'react';
import { graphql, PageProps } from 'gatsby';

import BlogPost from '../ui/organisms/BlogPost';

import Layout from './Layout';

const BlogTemplate = ({
  data: { markdownRemark: post },
}: // eslint-disable-next-line no-undef
PageProps<Queries.BlogPostBySlugQuery>) => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    author: {
      '@type': 'Organization',
      name: 'Alysia Lynn',
    },
    datePublished: post?.frontmatter?.date,
    description: post?.frontmatter?.description,
    headline: post?.frontmatter?.title,
    image: 'https://theplantifullife.ca/logo.png',
    mainEntityOfPage: {
      '@id': `https://www.theplantifullife.ca${post?.fields?.slug}`,
      '@type': 'WebPage',
    },
    publisher: {
      '@type': 'Organization',
      logo: {
        '@type': 'ImageObject',
        url: '/logo.png',
      },
      name: 'Plantiful',
    },
  };

  const defaultBlogMetaImage = './images/cuttings-meta-image.png';
  const defaultTwitterImage = './images/cuttings-twitter-image.png';

  return (
    <Layout
      image={post?.frontmatter?.metaImage || defaultBlogMetaImage}
      schemaMarkup={schemaMarkup}
      title={post?.frontmatter?.title || 'Blog - Plantiful'}
      twitterImage={post?.frontmatter?.twitterImage || defaultTwitterImage}
    >
      <section>
        <BlogPost post={post} />
      </section>
    </Layout>
  );
};

export default BlogTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        imageName
        twitterImage
        metaImage
        category
        author
        readTime
      }
    }
  }
`;
