module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-5c2b21ceeb/0/cache/gatsby-plugin-catch-links-npm-5.11.0-968e140504-3d274fc502.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-7e639ce943/0/cache/gatsby-plugin-manifest-npm-5.11.0-857fd9ff94-cada97619e.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#e0e0e0","display":"standalone","icon":"src/images/favicon.ico","name":"Plantiful","start_url":"/","theme_color":"#a2b59f","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3ad37beb3ce194edfd9cf417a6308c5e"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-38aeaf2baa/0/cache/gatsby-plugin-styled-components-npm-6.11.0-c502b4e601-e7dd066214.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-35762b8105/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
