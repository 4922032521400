import React from 'react';

type LogoProps = {
  fill: string;
  size: string | number;
};

const Logo = ({ fill, size }: LogoProps) => (
  <svg id="Layer_1" viewBox="0 0 680.48 175" width={size} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M61.81,140.6c-5.73,0-9.9-.72-13.06-1.69-1.38-.42-2.82.45-3.11,1.86l-5.11,24.73c-.28,1.37.63,2.69,2,2.93l9.14,1.54c1.39.23,2.29,1.58,2,2.95l-.03.14c-.25,1.13-1.25,1.94-2.41,1.94H2.46c-1.57,0-2.74-1.45-2.41-2.99h0c.21-.98.99-1.73,1.98-1.9l9.14-1.62c.99-.18,1.77-.93-.02-1.91l24.13-113.32c2.29-1.35,1.41-2.67.06-2.93l-7.46-1.44c-1.36-.26-2.23-1.59-1.94-2.94l.04-.2c.25-1.13,1.25-1.94,2.41-1.94h33.74c1.58,0,2.75,1.46,2.4,3l-.06.26c-.51,2.27,2.11,3.92,3.93,2.47,5.52-4.41,13.42-8.33,22-8.33,17.2,0,26.4,14,26.4,33.4,0,37.6-25.4,66-55,66ZM76.81,53.2c-5.19,0-11.31,3.88-13.9,7.38-.2.27-.33.59-.4.92l-13.7,64.76c-.13.65,0,1.33.36,1.89,2.36,3.66,6.66,6.05,11.64,6.05,17.2,0,28.4-47.6,28.4-68,0-12-4-13-12.4-13Z"
      fill={fill}
    />
    <path
      d="M117.61,126.6c0-3.57.79-7.34,1.19-10.13,0-.04.01-.09.02-.13l22.31-104.52c.26-1.21.47-2.41-.75-2.64l-9.12-1.55c-1.23-.23-2.02-1.44-1.75-2.67l.1-.44c.23-1.01,1.13-1.72,2.16-1.72h35.5c1.41,0,2.46,1.3,2.17,2.68l-23.23,109.12c-.4,2.4-1.2,6.4-1.2,8.2,0,3.2,2.2,4.6,5.6,4.6,3.2,0,7-2,10-4h.8l-1.24,4.79c-.1.4-.31.76-.6,1.05-7.26,6.96-19.31,11.76-26.95,11.76s-15-5-15-14.4Z"
      fill={fill}
    />
    <path
      d="M163.98,107.8c0-38.2,23.32-66.6,51.98-66.6,9.01,0,15.31,2.54,21.11,7,.92.7,2.13.67,3.02-.08l6.33-5.33c.45-.38,1-.59,1.57-.59h0c1.66,0,2.9,1.72,2.55,3.54l-13.04,69.06c-.53,2.4-1.07,6.2-1.07,8,0,3.2,1.96,4.6,4.98,4.6,2.85,0,6.05-2,8.54-4h.89l-1.05,4.55c-.13.55-.39,1.05-.76,1.44-6.81,6.88-17.58,11.61-23.46,11.61-6.62,0-10.88-3.24-12.56-8.78-.6-1.99-2.82-2.64-4.2-1.22-4.9,5.03-12.74,10.01-21.33,10.01-15.31,0-23.5-13.4-23.5-33.2ZM212.33,120.6l12.15-64.72c.14-.74.02-1.52-.34-2.17-2.04-3.74-5.41-5.91-9.96-5.91-15.13,0-25.45,40.4-25.45,60.6,0,11.8,3.38,20.6,10.86,20.6,4.58,0,9.98-3.82,12.32-7.3.22-.32.36-.71.43-1.1Z"
      fill={fill}
    />
    <path
      d="M348.99,128.2c-.09.39-.28.75-.55,1.03-6.83,7.15-18.35,11.77-25.35,11.77s-14.1-5-14.1-14.4c0-3.6.56-7.2,1.13-10l9.21-42.2c.56-3,.94-6.8.94-8.4,0-5.4-3.01-10.2-9.21-10.2s-11.6,4.07-16.02,8.11c-.35.32-.58.74-.67,1.22l-14.35,71.72c-.2,1.02-1.05,1.75-2.04,1.75h-21.12c-1.33,0-2.31-1.3-2.04-2.68l16.69-82.9c.24-1.22-.5-2.41-1.65-2.64l-7.65-1.55c-1.15-.23-1.89-1.43-1.64-2.65l.13-.63c.21-1.02,1.06-1.74,2.03-1.74h32.6c1.32,0,2.31,1.29,2.04,2.67l-1.08,5.47c-.41,2.08,1.9,3.52,3.4,2.12,8.06-7.52,17.76-12.86,27.72-12.86,11.47,0,18.8,7,18.8,20.2,0,3.8-.38,8.2-1.5,13.4l-8.82,39.93s-.02.08-.02.13c-.38,2.4-1.12,6.15-1.12,7.94,0,3.2,2.07,4.6,5.26,4.6,3.01,0,6.58-2,9.4-4h.75l-1.17,4.81Z"
      fill={fill}
    />
    <path
      d="M356.4,124.6c0-4.37.99-9.53,1.39-12.34,0-.04.01-.08.02-.12l11.81-57.54c.3-1.45-.81-2.8-2.28-2.8h-6.1c-1.47,0-2.57-1.35-2.29-2.79h0c.15-.76.68-1.39,1.39-1.69,17.68-7.47,28.54-15.23,38.16-28.54.44-.61,1.14-.98,1.89-.98h1.12c1.49,0,2.59,1.37,2.28,2.82l-4.38,20.36c-.31,1.45.79,2.82,2.28,2.82h14.4c1.49,0,2.6,1.39,2.27,2.84l-.75,3.34c-.24,1.06-1.18,1.82-2.27,1.82h-16.23c-1.11,0-2.06.78-2.28,1.87l-11.62,57.13c-.6,3-1.2,6.4-1.2,8.8,0,4.8,5.2,7.8,9.8,7.8,6,0,12-3.4,14.8-5.4h.8l-1.22,4.88c-.12.47-.37.89-.73,1.2-7.45,6.43-19.58,12.72-32.85,12.72-9,0-18.2-5.6-18.2-16.2Z"
      fill={fill}
    />
    <path
      d="M419,126.6c0-3.6.6-7.4,1.2-10.2l13.64-63.03c.31-1.41-.61-2.8-2.03-3.07l-7.42-1.41c-1.42-.27-2.34-1.66-2.03-3.07h0c.26-1.18,1.3-2.02,2.51-2.02h34.56c1.63,0,2.85,1.5,2.51,3.1l-14.34,67.7c-.4,2.4-1.2,6.4-1.2,8.2,0,3.2,2.2,4.6,5.6,4.6,3.2,0,7-2,9.8-4h1l-1.21,4.68c-.12.47-.37.9-.73,1.23-7.44,6.92-19.25,11.69-27.06,11.69s-14.8-5-14.8-14.4ZM438,16.2c0-7.8,7.4-14.2,16-14.2,7.8,0,14.2,5.2,14.2,12.4,0,8.2-7.8,14.4-16.2,14.4s-14-5.2-14-12.6Z"
      fill={fill}
    />
    <path
      d="M423.6,161.4c0-8.6,7.8-13,15-13,5.8,0,11,3.4,11,9.8,0,2.33-.75,4.99-2.32,7.51-1.03,1.66.35,3.8,2.26,3.39,12.09-2.56,16.51-14.97,21.25-38.3l15.08-76.36c.27-1.37-.78-2.64-2.17-2.64h-8.8c-1.4,0-2.45-1.28-2.17-2.65h0c.17-.83.79-1.49,1.6-1.71l12.37-3.3c.8-.21,1.4-.86,1.58-1.67,6.04-26.79,28.32-42.48,52.92-42.48,14,0,20.2,6.4,20.2,13.4,0,8.6-6.8,12.8-13.4,12.8s-12-3.8-12-10c0-2.54.45-4.72,1.99-7.14,1.05-1.65-.32-3.79-2.24-3.42-11.84,2.31-15.71,13.6-20.38,35.47-.29,1.38.75,2.69,2.16,2.69h14.1c1.42,0,2.47,1.32,2.16,2.7l-.8,3.57c-.23,1.01-1.12,1.73-2.16,1.73h-15.81c-1.06,0-1.97.75-2.17,1.79l-16.25,77.21c-5.6,29-27.4,44.2-52.6,44.2-13.8,0-20.4-6.6-20.4-13.6Z"
      fill={fill}
    />
    <path
      d="M537.13,121.6c0-3.4.34-8.6,1.38-13.2l10.6-55.37c.23-1.22-.45-2.42-1.51-2.66l-7-1.55c-1.05-.23-1.74-1.43-1.51-2.65l.12-.63c.19-1.02.97-1.74,1.86-1.74h30.54c1.22,0,2.12,1.31,1.86,2.69l-11.38,60.31c-.52,3-1.21,7.6-1.21,9.6,0,6.2,2.24,10,8.1,10s10.32-4.25,14.48-8.31c.33-.32.55-.75.64-1.24l12.98-71.29c.19-1.02.96-1.76,1.87-1.76h19.36c1.21,0,2.12,1.3,1.87,2.67l-12.43,68.33c-.52,2.4-1.03,6.2-1.03,8,0,3.2,1.9,4.6,4.82,4.6,2.76,0,5.86-2,8.27-4h.86l-1.06,4.76c-.09.42-.28.79-.56,1.08-6.58,6.96-17.11,11.75-23.01,11.75-7.06,0-12.92-5-12.92-15,0-.8,0-1.6.17-2.6-8.1,9.8-18.09,17.6-28.94,17.6-11.89,0-17.23-6.6-17.23-19.4Z"
      fill={fill}
    />
    <path
      d="M628.6,126.6c0-3.57.79-7.34,1.19-10.13,0-.04.01-.09.02-.13l22.31-104.52c.26-1.22-.53-2.41-1.75-2.64l-8.12-1.55c-1.23-.23-2.02-1.44-1.75-2.67l.1-.44c.23-1.01,1.13-1.72,2.16-1.72h35.5c1.41,0,2.46,1.3,2.17,2.68l-23.23,109.12c-.4,2.4-1.2,6.4-1.2,8.2,0,3.2,2.2,4.6,5.6,4.6,3.2,0,7-2,10-4h.8l-1.24,4.79c-.1.4-.31.76-.6,1.05-7.26,6.96-19.31,11.76-26.95,11.76s-15-5-15-14.4Z"
      fill={fill}
    />
  </svg>
);

export default Logo;
